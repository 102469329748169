import React, { useState, useRef, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { CKEditor } from 'ckeditor4-react'
import styles from './share-create.module.scss'
import parse from 'html-react-parser'
import { ROUTES } from 'consts'

//import antd
import { Upload, Button, Input, notification, Row, Col } from 'antd'
import {
  LoadingOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

//import icons
import prevButton from 'assets/icons/Vector.png'

// api
import { uploadFile } from 'apis/upload'
import { createBlog, updateBlog } from 'apis/blog'

export default function ShareCreate() {
  const history = useHistory()
  const editor = useRef(null)
  const location = useLocation()

  const [content, setContent] = useState('')
  const [title, setTitle] = useState('')
  const [picture, setPicture] = useState('')
  const [loading, setLoading] = useState(false)
  const [links, setLinks] = useState([])

  //text editor
  const config = {
    readonly: true, // all options from https://xdsoft.net/jodit/doc/
  }
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  //antd upload

  const handleChangeImg = async (info) => {
    setLoading(true)
    const urlImg = await uploadFile(info.file.originFileObj)
    setPicture(urlImg)
    setLoading(false)
  }
  const handleChangeTitle = (e) => {
    // console.log(e.target.value);
    setTitle(e.target.value)
  }
  const handleChangeContent = (e) => {
    const value = e.editor.getData()
    setContent(value)
  }
  const _createShare = async () => {
    const body = {
      title: title,
      image: picture,
      content: content,
      videos: links,
    }
    console.log(body)
    try {
      let res
      if (!location.state) {
        res = await createBlog(body)
      } else {
        const id = location.state.idBlog
        res = await updateBlog(body, id)
      }
      // console.log(res)
      if (res.status === 200) {
        if (res.data.success) {
          {
            location.state
              ? notification.success({
                  message: 'Cập nhật bài viết thành công',
                })
              : notification.success({ message: 'Tạo bài viết thành công' })
          }
          history.goBack()
        }
      } else {
        {
          location.state
            ? notification.error({
                message: res.data.mess || 'Cập nhật bài viết thất bại',
              })
            : notification.error({
                message: res.data.mess || 'Tạo bài viết thất bại',
              })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const addLinkVideo = () => {
    const newLinks = [...links]
    newLinks.push('')
    setLinks([...newLinks])
  }

  const removeLinkVideo = (index) => {
    const newLinks = [...links]
    newLinks.splice(index, 1)
    setLinks([...newLinks])
  }

  const inputValueLink = (value, index) => {
    const newLinks = [...links]
    newLinks[index] = value
    setLinks([...newLinks])
  }

  useEffect(() => {
    if (location.state) {
      setPicture(location.state.image);
      setTitle(location.state.title);
      setContent(location.state.content);
      setLinks(location.state.videos)
    }
    // console.log(location.state)
  }, []);

  return (
    <div>
      <div className={styles['share-create-header']}>
        <div className={styles['share-create-header-left']}>
          <Button type="text">
            <img
              onClick={() => history.goBack()}
              className={styles['share-create-header-icon']}
              src={prevButton}
              alt=""
            />
          </Button>
          <h2>{location.state ? 'Cập nhật' : 'Tạo'} bài viết</h2>
        </div>
        <Button
          onClick={_createShare}
          type="primary"
          style={{ paddingRight: 25, paddingLeft: 25 }}>
          {location.state ? 'Cập nhật' : 'Tạo'}
        </Button>
      </div>

      <div>
        <h3>Hình ảnh</h3>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          // beforeUpload={beforeUpload}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          onChange={handleChangeImg}>
          {picture ? (
            <img src={picture} alt="avatar" style={{ width: '100%' }} />
          ) : (
            uploadButton
          )}
        </Upload>
      </div>

      <div>
        <h3>Tiêu đề</h3>
        <Input
          value={title}
          onChange={handleChangeTitle}
          name="title"
          placeholder="Nhập tiêu đề"
        />
      </div>
      <div style={{ marginBottom: 17 }}>
        <Row align="middle" style={{ marginTop: '15px', marginBottom: '0px' }}>
          <h3 style={{ marginBottom: 0 }}>Links video</h3>
          <PlusCircleOutlined
            onClick={addLinkVideo}
            style={{ fontSize: 22, marginLeft: 8, color: '#DD631B' }}
          />
        </Row>
        {links.map((item, index) => {
          const InputLink = () => (
            <Input
              style={{ marginTop: 10 }}
              onBlur={(e) => inputValueLink(e.target.value, index)}
              defaultValue={item}
              placeholder="Nhập đường dẫn video"
            />
          )
          return (
            <Row wrap={false} align="middle">
              <InputLink />
              <DeleteOutlined
                onClick={() => removeLinkVideo(index)}
                style={{
                  fontSize: 16,
                  color: 'red',
                  marginLeft: 20,
                  marginTop: 10,
                }}
              />
            </Row>
          )
        })}
      </div>
      <div>
        <h3>Nội dung</h3>
        <CKEditor
          initData={location.state ? parse(location.state.content) : ''}
          onChange={handleChangeContent}
        />
      </div>
    </div>
  )
}
