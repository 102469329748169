import React from 'react'

export default function Privacy() {
  return (
    <div style={{ backgroundColor: '#ECF2F3' }}>
      <div
        style={{
          padding: '50px 170px',
        }}>
        <h1 style={{ textAlign: 'center', fontWeight: 700, fontSize: 38 }}>
          Standard subscription terms and conditions
        </h1>
        <h1 style={{ fontWeight: 700 }}>
          1. Scope and use of the subscription
        </h1>
        <p style={{ fontSize: 14, fontWeight: 500 }}>
          The subscription grants the Customer (being the legal entity that has
          taken out the subscription) the right to use Electric system and
          services (“the Services”). The subscription may not be used by other
          people or organizations.
        </p>

        <h1 style={{ fontWeight: 700 }}>2. Acceptance of the subscription</h1>
        <p style={{ fontSize: 14, fontWeight: 500 }}>
          The subscription terms for the Services are accepted by the Customer
          by signing the Formal Quote document and forward it by email to{' '}
          <a href="mailto:tunv@vnk.com.vn">tunv@vnk.com.vn</a>. The signed
          Formal Quote and with these terms and conditions will be the
          “Agreement”.
        </p>

        <h1 style={{ fontWeight: 700 }}>
          3. Duration and termination of the subscription
        </h1>
        <p style={{ fontSize: 14, fontWeight: 500 }}>
          The monthly annual subscription runs from the date the Customer signs
          the Formal Quote to the end of that calendar quarter, plus the
          following calendar quarter (for example, if the order is placed
          February 15, the subscription runs to the end of June). After that
          period, the subscription runs for three months (a quarter) at a time.
          At the end of the three months, the subscription will automatically be
          renewed for another three months (a quarter) unless terminated by the
          Customer. The minimum subscription period is six months.
        </p>
        <p style={{ fontSize: 14, fontWeight: 500 }}>
          A discount is applicable for annual prepayment.
        </p>
        <p style={{ fontSize: 14, fontWeight: 500 }}>
          The monthly annual subscription may be terminated by email to{' '}
          <a href="mailto:tunv@vnk.com.vn">tunv@vnk.com.vn</a>, one month before
          the expiry of the subscription. The agreement cannot be terminated for
          a period of subscription already initiated and the Customer will not
          be entitled to a refund for that subscription period.
        </p>
        <p style={{ fontSize: 14, fontWeight: 500 }}>
          Light subscriptions run for 24 consecutive months from the 1st of the
          month of the Agreement and will continue to auto renew for 12 months
          in array until terminated by email to{' '}
          <a href="mailto:tunv@vnk.com.vn">tunv@vnk.com.vn</a> one month before
          the end of the current subscription period. The agreement cannot be
          terminated for a period of subscription already initiated and the
          Customer will not be entitled to a refund for that subscription
          period.
        </p>
        <p style={{ fontSize: 14, fontWeight: 500 }}>
          The Customer may terminate any subscription free of charge within two
          weeks of placing the first order for the subscription, provided that
          the Customer has not started using the Services. Electrical
          Engineering Tool shall (without prejudice to any other rights or
          remedies it may have) be allowed to terminate the subscription
          immediately without giving the Customer prior notice if the Services
          or subscription is misused (including, but not limited to, use by
          other people or organizations).
        </p>
        <p style={{ fontSize: 14, fontWeight: 500 }}>
          In the event of the Customer filing for bankruptcy, the subscription
          will be terminated, and all Services will be terminated immediately,
          unless otherwise specifically agreed in writing between the Parties.
        </p>
        <h1 style={{ fontWeight: 700 }}>4. Price and payment terms</h1>
        <p style={{ fontSize: 14, fontWeight: 500 }}>
          The charges are listed on the Formal Quote. Electrical Engineering
          Tool guarantees that no price increases will be introduced.
        </p>
        <p style={{ fontSize: 14, fontWeight: 500 }}>
          Electrical Engineering Tool will not be responsible for any loss,
          damage, costs, expenses or other claims of the Customer or any third
          party resulting from the suspension of the Services.
        </p>

        <em style={{ fontSize: 14 }}>The Customer agrees to</em>
      </div>
    </div>
  )
}
