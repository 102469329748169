export const compare = (a, b, key, convert) => {
  if (convert)
    return convert(a[key]) > convert(b[key])
      ? 1
      : convert(a[key]) === convert(b[key])
      ? 0
      : -1
  return a[key] > b[key] ? 1 : a[key] === b[key] ? 0 : -1
}

export const compareCustom = (a, b) => {
  return a > b ? 1 : a === b ? 0 : -1
}
export function removeAccents(text, removeSpace = false) {
  if (removeSpace && typeof removeSpace != 'boolean') {
    throw new Error('Type of removeSpace input must be boolean!')
  }
  text = (text + '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
  if (removeSpace) {
    text = text.replace(/\s/g, '')
  }
  return text
}

export function formatCash(str) {
  if (str) return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  else return 0
}
