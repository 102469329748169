import React, { cloneElement } from 'react'
import { Redirect } from 'react-router-dom'
import { ROUTES } from 'consts'
import { decodeToken } from 'react-jwt'

/**
 *
 * @param {Object} props
 * @param {Array<String>} props.permissions
 * @param {React.ReactChildren} props.children
 */
const Authentication = ({ permissions, title, children, ...props }) => {
  const payload = decodeToken(localStorage.getItem('accessToken'))
  //modify title
  document.title = title

  //check đã đăng nhập chưa hoặc token còn hạn -> vào trang home
  if (!permissions) {
    if (!payload || new Date(payload.exp * 1000).getTime() < Date.now()) {
      return cloneElement(children, props)
    }
    return <Redirect to={ROUTES.ORDER} />
  }

  //check login ?
  if (!payload) {
    return <Redirect to={ROUTES.LOGIN} />
  }

  return cloneElement(children, props)
}

export default Authentication
