import React from 'react'

import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import { ROUTES } from 'consts'

//base layout
import UI from 'components/Layout/UI'
import Authentication from 'components/authentication'

//views
import Login from './login'
import NotFound from './not-found/404'
import Device from './devices'
import Order from './order'
import Share from './share'
import ShareCreate from './share-create'
import Setting from './setting'
import Privacy from './privacy'
import User from './user'
import Employee from './employee'
import Package from './package'

const DEFINE_ROUTER = [
  {
    path: ROUTES.DEVICE,
    Component: () => <Device />,
    title: 'Device',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.ORDER,
    Component: () => <Order />,
    title: 'Order',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.EMPLOYEE,
    Component: () => <Employee />,
    title: 'Order',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SHARE,
    Component: () => <Share />,
    title: 'Share',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SHARE_CREATE,
    Component: () => <ShareCreate />,
    title: 'ShareCreate',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SETTING,
    Component: () => <Setting />,
    title: 'ShareCreate',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.USER,
    Component: () => <User />,
    title: 'Quản lí nhân viên',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.PACKAGE,
    Component: () => <Package />,
    title: 'Quản lí gói',
    permissions: [],
    exact: true,
  },
]

const AUTH_ROUTER = [
  {
    path: ROUTES.LOGIN,
    Component: () => <Login />,
    exact: true,
    title: 'Login',
    permissions: [],
  },
  {
    path: ROUTES.PRIVACY,
    Component: () => <Privacy />,
    title: 'Privacy',
    permissions: [],
    exact: true,
  },
]

export default function Views() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true}>
          <Redirect to={ROUTES.DEVICE} />
        </Route>

        {AUTH_ROUTER.map(({ Component, ...rest }, index) => (
          <Route {...rest} key={index}>
            <Component />
          </Route>
        ))}

        {DEFINE_ROUTER.map(({ Component, ...rest }, index) => (
          <Route {...rest} key={index}>
            <Authentication {...rest}>
              <UI>
                <Component />
              </UI>
            </Authentication>
          </Route>
        ))}

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
