import { ACTION, ROUTES } from 'consts'
import { decodeToken } from 'react-jwt'

// nhận data từ server
const initialState = {
  dataUser: {},
  loading: false,
}

var login = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.LOGIN: {
      var data = decodeToken(action.data.accessToken)
      // console.log(data)
      if (data) {
        localStorage.setItem('accessToken', action.data.accessToken)
        if (action.data.user) var { username, role, balance } = action.data.user

        if (username) localStorage.setItem('username', username)

        var { balance, username, role } = data

        if (balance) localStorage.setItem('balance', JSON.stringify(balance))
        if (username) localStorage.setItem('username', JSON.stringify(username))
        if (role) localStorage.setItem('role', JSON.stringify(role))
      }

      return {
        ...state,
        dataUser: decodeToken(action.data.accessToken),
      }
    }

    case ACTION.LOGOUT: {
      localStorage.clear()
      return {
        ...state,
        dataUser: {},
      }
    }

    case ACTION.LOADING: {
      state.loading = action.data
      return state
    }

    default:
      return state
  }
}
export default login
