import React, { useEffect, useState } from 'react'
import styles from './user.module.scss'

import { useDispatch, useSelector } from 'react-redux'

//import antd
import { Table } from 'antd'

//import moment
import moment from 'moment'

//import apis
import { getDevices } from 'apis/device'

export default function Device() {
  const dispatch = useDispatch()

  const [infor, setInfor] = useState([])
  console.log(infor)
  const [loadingTable, setLoadingTable] = useState(false)
  const [page, setPage] = useState(1)
  const [countPage, setCountPage] = useState('')
  const [paramsFilter, setParamsFilter] = useState({ page: 1, pageSize: 8 })

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      render: (value, item, index) =>
        (page - 1) * paramsFilter.pageSize + index + 1,
      width: '5%'
    },
    {
      title: 'Tên thiết bị',
      dataIndex: 'device_name',
      align: 'center',
      width: '30%'
    },
    {
      title: 'Mã thiết bị',
      dataIndex: 'device_id',
      align: 'center',
      width: '30%'
    },
    {
      title: 'Thời gian đăng nhập',
      dataIndex: 'date_create',
      align: 'center',
      render: (text, record, index) => 
        moment(record.date_create).format('DD/MM/YYYY, H:mm:ss'),
    },
  ]

  const _getDevices = async () => {
    try {
      setLoadingTable(true)
      const res = await getDevices(paramsFilter)
      setCountPage(res.data.count)
      if (res.data.data) {
        setInfor(res.data.data)
      }
      setLoadingTable(false)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    _getDevices(paramsFilter)
  }, [paramsFilter])

  return (
    <div>
      <h2 className={styles['device-format-text']}>Quản lý thiết bị</h2>
      <div className={styles['device-format-table']}>
        <Table
          columns={columns}
          dataSource={infor}
          size="small"
          loading={loadingTable}
          pagination={{
            total: countPage,
            current: paramsFilter.page,
            onChange(page, pageSize) {
              setParamsFilter({
                page: page,
                pageSize: pageSize,
              })
              setPage(page)
            },
            pageSize: paramsFilter.pageSize,
            // page: paramsFilter.pages,
          }}
        />
      </div>
    </div>
  )
}
