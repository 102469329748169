export const ACTION = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  LOADING: 'LOADING',
  DEVICES: 'DEVICES',
  GET_PRODUCTS: 'GET_PRODUCTS',
}

export const ROUTES = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  NEW_PASSWORD: '/new-password',
  MAIL: '/mail',
  DEVICE: '/device',
  ORDER: '/order',
  SHARE: '/share',
  SHARE_CREATE: '/share/create',
  ACCOUNT_SETTING: '/account-setting',
  DISPUTE: '/dispute',
  DISPUTE_DETAIL: '/dispute-detail',
  SETTING: '/setting',
  USER: '/user',
  PACKAGE: '/package',
  PRIVACY: '/privacy',
  EMPLOYEE: '/employee',
}

export const PERMISSIONS = {
  quan_li_don_hang: 'quan_li_don_hang',
  chia_se: 'chia_se',
  cai_dat: 'cai_dat',
  quan_li_thiet_bi: 'quan_li_thiet_bi',
  quan_li_nhan_vien: 'quan_li_nhan_vien',
  quan_li_goi: 'quan_li_goi',
}

export const IMAGE_DEFAULT =
  'https://s3.ap-northeast-1.wasabisys.com/ecom-fulfill/2021/09/12/8f70ac1d-4070-46cb-9ed9-577cd38d7f3c_noimage.jpg'

export const VERSION_APP = '0.0.8'
