import { get, patch, post, destroy } from './httpClient'
export const apiAddUser = (object) => post('/user', object)
export const apiCreateUser = (object) => post('/user/create', object)
export const apiAllUser = (query) => get('/user', query)
export const apiAllRole = () => get('/role/getrole')
export const apiSearch = (object) => get('/user', object)
export const updateUser = (body, id) => patch(`/user/updateuser/${id}`, body)
export const deleteUser = (id) => destroy('/user/delete/' + id)
export const resetPassword = (body, id) =>
  patch('/user/change-pass/' + id, body)
