import React, { useState } from 'react'
import styles from './login.module.scss'

import { useHistory } from 'react-router-dom'

import { ACTION, ROUTES } from 'consts'
import { useDispatch } from 'react-redux'

//antd
import { Form, Input, Button, Checkbox, notification } from 'antd'

//icons antd
import { UserOutlined, LockOutlined } from '@ant-design/icons'

//import logo
import logo from 'assets/logo1.png'

// api
import { login } from '../../apis/auth'

export default function Login() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const _postLogin = async () => {
    await form.validateFields()

    try {
      setLoading(true)
      const formData = form.getFieldsValue()
      const res = await login(formData)

      if (res.status === 200) {
        if (res.data.accessToken) {
          dispatch({ type: ACTION.LOGIN, data: res.data })
          history.push(ROUTES.ORDER)
        } else {
          notification.error({
            message: 'Đăng nhập thất bại',
            description: 'Tài khoản hoặc mật khẩu không đúng',
          })
        }
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  return (
    <div className={styles['login']}>
      <div className={styles['login_img_parent']}>
        <img className={styles['login_img']} src={logo} alt="" />
      </div>
      <Form className={styles['login_bottom']} form={form}>
        <p className={styles['login_bottom_email_text']}>Tài khoản</p>
        <Form.Item
          className={styles['login_bottom_email']}
          name="email"
          rules={[{ required: true, message: 'Vui lòng nhập tài khoản!' }]}>
          <Input
            size="large"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Nhập tài khoản"
          />
        </Form.Item>
        <p className={styles['login_bottom_password_text']}>Mật khẩu</p>
        <Form.Item
          className={styles['login_bottom_password']}
          name="password"
          rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}>
          <Input.Password
            size="large"
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Nhập mật khẩu"
          />
        </Form.Item>
        <div className={styles['login_bottom_left']}>
          <Form.Item valuePropName="checked">
            <Checkbox className={styles['login_bottom_left_checkbox']}>
              Ghi nhớ mật khẩu
            </Checkbox>
          </Form.Item>
        </div>
        <div className={styles['login_bottom_left_button_parent']}>
          <Form.Item>
            <Button
              loading={loading}
              onClick={_postLogin}
              className={styles['login_bottom_left_button']}
              type="primary"
              htmlType="submit">
              Đăng nhập
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}
