import React, { useState, useEffect, useRef } from 'react'
import styles from './users.module.scss'
import moment from 'moment'
import { ACTION } from 'consts'
import { useDispatch } from 'react-redux'

import {
  Col,
  Row,
  Drawer,
  Table,
  Form,
  Button,
  Input,
  notification,
  Space,
  Popconfirm,
} from 'antd'

//icons
import {
  PlusOutlined,
  EditOutlined,
  SaveOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

//apis
import { apiAllUser, updateUser, apiCreateUser, deleteUser } from 'apis/user'

export default function SellerTransaction() {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [users, setUsers] = useState([])
  const [countUsers, setCountUsers] = useState(0)
  const [loading, setLoading] = useState(false)
  const [paramsFilter, setParamsFilter] = useState({ page: 1, page_size: 20 })
  const [valueSearch, setValueSearch] = useState('')
  const [visibleDeposit, setVisileDeposit] = useState(false)
  const [isUpdateUser, setIsUpdateUser] = useState(false) //check co dang update user ko ?

  const showDrawerDeposit = () => {
    setVisileDeposit(true)
  }
  const onCloseDeposit = () => {
    setVisileDeposit(false)
  }

  const typingTimeoutRef = useRef(null)
  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) paramsFilter.email = value
      else delete paramsFilter.email
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 750)
  }

  const _createOrUpdateUser = async () => {
    try {
      await form.validateFields()

      const dataForm = form.getFieldsValue()
      const body = { ...dataForm, role_id: 2 }
      let res
      if (isUpdateUser) res = await updateUser(body)
      else res = await apiCreateUser(body)
      console.log(res)
      if (res.status === 200) {
        getAllUsers()
        notification.success({ message: 'Thêm nhân viên thành công' })
        onCloseDeposit()
      } else
        notification.error({
          message: res.data.message || 'Thêm nhân viên thất bại',
        })
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'email',
    },

    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      render: (text) => text && moment(text).format('YYYY-MM-DD'),
    },
    {
      title: '',
      render: (text, record) => (
        <Popconfirm
          onConfirm={() => _deleteUser(record.idUser)}
          title="Bạn có muốn xóa nhân viên này không?">
          <DeleteOutlined style={{ color: 'red', fontSize: 17 }} />
        </Popconfirm>
      ),
    },
  ]

  const getAllUsers = async () => {
    try {
      setLoading(true)
      const res = await apiAllUser({ ...paramsFilter, role: 'employee' })
      console.log(res)
      if (res.status === 200) {
        setUsers(res.data.data)
        setCountUsers(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const _deleteUser = async (id) => {
    try {
      setLoading(true)
      const res = await deleteUser(id)
      setLoading(false)

      if (res.status === 200) {
        getAllUsers()
        notification.success({ message: 'Xóa nhân viên thành công' })
      } else
        notification.error({
          message:
            res.data.message || res.data.mes || 'Xóa nhân viên thành công',
        })
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAllUsers()
  }, [paramsFilter])

  //reset drawer
  useEffect(() => {
    if (!visibleDeposit) {
      form.resetFields()
      setIsUpdateUser(false)
    }
  }, [visibleDeposit])

  return (
    <div className={styles['orders_manager']}>
      <Drawer
        title={isUpdateUser ? 'Cập nhật nhân viên' : 'Thêm nhân viên'}
        width={800}
        onClose={onCloseDeposit}
        visible={visibleDeposit}
        footer={
          <Row justify="end">
            <Space>
              <Button
                icon={<SaveOutlined />}
                type="primary"
                onClick={_createOrUpdateUser}>
                {isUpdateUser ? 'Cập nhật' : 'Tạo'}
              </Button>
              <Button onClick={onCloseDeposit}>Cancel</Button>
            </Space>
          </Row>
        }>
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Tên đăng nhập"
                name="email"
                rules={[
                  { required: true, message: 'Vui lòng nhập tên đăng nhập' },
                ]}>
                <Input placeholder="Nhập tên đăng nhập" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Mật khẩu"
                name="password"
                rules={[
                  {
                    required: isUpdateUser ? false : true,
                    message: 'Vui lòng nhập mật khẩu',
                  },
                ]}>
                <Input.Password placeholder="Nhập mật khẩu" />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <div style={{ color: 'red' }}>
            * Quyền thao tác của nhân viên: Đơn hàng và bài viết
          </div>
        </Form>
      </Drawer>
      <div className={styles['balance_manager']}>
        <Row justify="space-between" align="middle">
          <h1 style={{ fontSize: 25 }}>Quản lý nhân viên</h1>
          <Button
            icon={<PlusOutlined />}
            type="primary"
            size="large"
            onClick={showDrawerDeposit}>
            Thêm nhân viên
          </Button>
        </Row>
        <br />
        <Row>
          <Col xs={24} sm={11} md={11} lg={11} xl={11}>
            <Input.Search
              value={valueSearch}
              onChange={onSearch}
              placeholder="Tìm kiếm nhân viên theo email"
              allowClear
            />
          </Col>
        </Row>
        <Table
          style={{ width: '100%', marginTop: 5 }}
          size="small"
          loading={loading}
          columns={columns}
          dataSource={users}
          pagination={{
            position: ['bottomLeft'],
            current: paramsFilter.page,
            defaultPageSize: 20,
            pageSizeOptions: [20, 30, 50, 100],
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              setParamsFilter({
                ...paramsFilter,
                page: page,
                page_size: pageSize,
              })
            },
            total: countUsers,
          }}
        />
      </div>
    </div>
  )
}
