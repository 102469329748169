import React, { useState, useEffect } from 'react'
import styles from './ui.module.scss'
import { useSelector } from 'react-redux'
import { PERMISSIONS, ROUTES, VERSION_APP } from 'consts'
import Permission from 'components/permission'

//components antd
import {
  Layout,
  Menu,
  Popover,
  Modal,
  Form,
  notification,
  Input,
  Row,
  Space,
  Button,
} from 'antd'

//icons antd
import {
  MenuFoldOutlined,
  LogoutOutlined,
  DeleteColumnOutlined,
  SettingOutlined,
  UserAddOutlined,
  UserOutlined,
  CodeSandboxOutlined,
  LockOutlined,
} from '@ant-design/icons'

//logo
import Logo from 'assets/logo1.png'

//icons antd
import iconShare from 'assets/icons/Group.png'
import iconOrder from 'assets/icons/Group1668.png'
import iconDevice from 'assets/icons/Group1669.png'

import { resetPassword } from 'apis/user'

import { Link, useRouteMatch } from 'react-router-dom'

const { Sider, Content } = Layout
export default function UI(props) {
  const dataUser = useSelector((state) => state.login.dataUser)
  console.log(dataUser)
  const routeMatch = useRouteMatch()
  const [collapsed, setCollapsed] = useState(false)

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  const onClickLogout = () => {
    let tmp = localStorage.DisputeColumns
    localStorage.clear()
    if (tmp) localStorage.setItem('DisputeColumns', tmp)
  }

  const MENUS = [
    // {
    //   path: ROUTES.DEVICE,
    //   permissions: [PERMISSIONS.quan_li_thiet_bi],
    //   icon: <img style={{ width: 14, height: 14 }} alt="" src={iconDevice} />,
    //   title: 'Quản lí thiết bị',
    // },
    {
      path: ROUTES.ORDER,
      permissions: [PERMISSIONS.quan_li_don_hang],
      icon: <img style={{ width: 14, height: 14 }} alt="" src={iconOrder} />,
      title: 'Quản lí đơn hàng',
    },
    {
      path: ROUTES.PACKAGE,
      permissions: [PERMISSIONS.quan_li_goi],
      icon: <CodeSandboxOutlined />,
      title: 'Quản lí gói',
    },
    {
      path: ROUTES.USER,
      permissions: [PERMISSIONS.quan_li_nhan_vien],
      icon: <UserAddOutlined />,
      title: 'Quản lí nhân viên',
    },
    {
      path: ROUTES.SHARE,
      permissions: [PERMISSIONS.chia_se],
      icon: <img style={{ width: 14, height: 14 }} alt="" src={iconShare} />,
      title: 'Chia sẻ',
    },
    {
      path: ROUTES.SETTING,
      permissions: [PERMISSIONS.cai_dat],
      icon: <SettingOutlined style={{ fontSize: '20px' }} />,
      title: 'Cài đặt',
    },
  ]

  const renderMenuItem = (_menu) => (
    <Permission permissions={_menu.permissions}>
      <Menu.Item icon={_menu.icon} key={_menu.path}>
        <Link to={_menu.path} style={{ fontWeight: 500 }}>
          {_menu.title}
        </Link>
      </Menu.Item>
    </Permission>
  )

  const ModalModifyPassword = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    const _resetPassword = async () => {
      try {
        await form.validateFields()
        const dataForm = form.getFieldsValue()
        if (dataForm.passwordNew !== dataForm.passwordNewAgain) {
          notification.open({ message: 'Xác nhận mật khẩu không đúng' })
          return
        }
        setLoading(true)
        const body = { password: dataForm.passwordNew }
        const res = await resetPassword(body, dataUser.idUser)
        setLoading(false)
        if (res.status === 200) {
          if (res.data.success) {
            toggle()
            form.resetFields()
            notification.success({ message: 'Đổi mật khẩu thành công' })
          } else
            notification.error({
              message:
                res.data.message ||
                res.data.mess ||
                'Đổi mật khẩu thất bại, vui lòng thử lại',
            })
        } else
          notification.error({
            message:
              res.data.message ||
              res.data.mess ||
              'Đổi mật khẩu thất bại, vui lòng thử lại',
          })
      } catch (err) {
        console.log(err)
        setLoading(false)
      }
    }

    return (
      <>
        <div onClick={toggle}>
          <div style={{ color: '#DD631B', paddingLeft: 10, cursor: 'pointer' }}>
            <LockOutlined
              style={{ fontSize: '1rem', marginRight: 10, color: ' #DD631B' }}
            />
            Đổi mật khẩu
          </div>
        </div>
        <Modal
          width={550}
          footer={
            <Row justify="end">
              <Space>
                <Button
                  onClick={() => {
                    toggle()
                    form.resetFields()
                  }}>
                  Đóng
                </Button>
                <Button
                  onClick={_resetPassword}
                  loading={loading}
                  type="primary">
                  Cập nhật
                </Button>
              </Space>
            </Row>
          }
          visible={visible}
          onCancel={() => {
            toggle()
            form.resetFields()
          }}
          title="Đổi mật khẩu">
          <Form form={form} layout="vertical">
            <Form.Item
              label="Mật khẩu mới"
              name="passwordNew"
              rules={[
                { message: 'Vui lòng nhập mật khẩu mới', required: true },
              ]}>
              <Input.Password
                onPressEnter={_resetPassword}
                placeholder="Nhập mật khẩu mới"
              />
            </Form.Item>

            <Form.Item
              label="Xác nhận khẩu mới"
              name="passwordNewAgain"
              rules={[
                { message: 'Vui lòng xác nhận mật khẩu mới', required: true },
              ]}>
              <Input.Password
                onPressEnter={_resetPassword}
                placeholder="Xác nhận mật khẩu mới"
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }

  const content = (
    <div className={styles['popoper']}>
      <ModalModifyPassword />
      <Link to="/login" onClick={onClickLogout}>
        <div className={styles['popoper_parent_bottom']}>
          <div>
            <LogoutOutlined />
          </div>
          <div>Đăng xuất</div>
        </div>
      </Link>
    </div>
  )

  return (
    <Layout className={styles['site-layout-background']}>
      <Sider
        className={styles['site-layout-background']}
        trigger={null}
        collapsible
        collapsed={collapsed}>
        <div>
          {/* style={{
            display: collapsed ? 'none' : '',
          }} */}
          <div
            className={
              styles[
                collapsed === false ? 'logo_left_icon' : 'logo_left_icon_small'
              ]
            }>
            <img className={styles['logo_left']} src={Logo} alt="" />
          </div>
        </div>
        <div
          className={
            collapsed ? styles['level_left_active'] : styles['level_left']
          }>
          Electrical
          <br />
          Engineer’s Tool
        </div>
        <div
          className={
            collapsed ? styles['gold_left_active'] : styles['gold_left']
          }>
          {dataUser.level && dataUser.level.name}
        </div>
        <Menu
          className={styles['site-layout-background']}
          theme="dark"
          mode="inline"
          selectedKeys={routeMatch.path}>
          {MENUS.map(renderMenuItem)}
          <Menu.Item
            onClick={onClickLogout}
            key="9"
            icon={<LogoutOutlined />}
            className={styles['site-layout-background-logout']}>
            <Link
              to="/login"
              className={styles['site-layout-background-logout']}>
              Đăng xuất
            </Link>
          </Menu.Item>
          <Menu.Item
            key="10"
            className={
              styles[
                collapsed === false ? 'active_version' : 'unactive_version'
              ]
            }>
            <p>Version: {VERSION_APP}</p>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className={styles['site-layout']}>
        <div className={styles['header_custom']}>
          <div className={styles['header_custom_toggle']}>
            <MenuFoldOutlined
              style={{ fontSize: '1.25rem', margin: '0 0' }}
              onClick={toggle}
            />
          </div>
          <div className={styles['header_custom_right']}>
            <Popover content={content}>
              <div className={styles['header_custom_right_users']}>
                <div>
                  <img
                    className={styles['header_custom_right_users_img']}
                    src="https://ecomfullfillment.s3.ap-southeast-1.amazonaws.com/1625138766309_ecomfullfillment.png"
                    alt=""
                  />
                </div>
                <div className={styles['header_custom_right_users_child']}>
                  <div
                    className={
                      styles['header_custom_right_users_child_username']
                    }
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}>
                    Admin
                  </div>
                  <div
                    className={
                      styles['header_custom_right_users_child_username_money']
                    }>
                    {/* ${balance.available_amount} */}
                  </div>
                </div>
              </div>
            </Popover>
          </div>
        </div>
        <Content style={{ padding: 16, backgroundColor: '#F0F2FA' }}>
          {props.children}
        </Content>
      </Layout>
    </Layout>
  )
}
