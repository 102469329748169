import React, { useState, useEffect } from 'react'
import styles from './order.module.scss'
import moment from 'moment'
import { formatCash } from 'utils'

//import antd
import { Table, Tag, Row, Select, Col } from 'antd'

import FilterDate from 'components/filter-date'

//import apis
import { getOrders } from 'apis/order'

export default function Order() {
  const [inforOrder, setInforOrder] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [countPage, setCountPage] = useState('')
  const [paramsFilter, setParamsFilter] = useState({ page: 1, pageSize: 20 })
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      render: (text, record, index) => index + 1,
      width: '5%',
    },
    {
      title: 'Thông tin gói',
      align: 'center',
      render: (text, record) =>
        record.package && (
          <div>
            <div>
              <b>Tên gói:</b> {record.package.name_package}
            </div>
            <div>
              <b>Số tiền:</b> {record.package.price}
            </div>
            <div>
              <b>Mô tả:</b> {record.package.description}
            </div>
          </div>
        ),
    },
    {
      title: 'Số tiền',
      render: (text, record) => formatCash(record.price || 0),
    },
    {
      title: 'SDT người mua',
      render: (text, record) =>
        (record.user && record.user.phone) || record.phone,
    },
    {
      title: 'Phương thức thanh toán',
      dataIndex: 'name_payment',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
    },
    {
      title: 'Trạng thái',
      render: (text, record) => (
        <Tag color={record.status ? 'success' : 'processing'}>
          {record.status ? 'Đã thanh toán' : 'Đợi thanh toán'}
        </Tag>
      ),
    },
    {
      title: 'Thời gian nâng cấp',
      dataIndex: 'create_date',
      align: 'center',
      render: (text, record) =>
        record.create_date &&
        moment(record.create_date).format('DD/MM/YYYY, HH:mm'),
    },
  ]

  const _getOrders = async () => {
    try {
      setLoadingTable(true)
      const res = await getOrders({ ...paramsFilter })
      if (res.status === 200) {
        setCountPage(res.data.count)
        setInforOrder(res.data.data)
      }
      setLoadingTable(false)
    } catch (err) {
      console.log(err)
      setLoadingTable(false)
    }
  }

  const _filterByOption = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  useEffect(() => {
    _getOrders()
  }, [paramsFilter])

  return (
    <div>
      <h2 className={styles['order-format-text']}>Quản lý đơn hàng</h2>
      <Row wrap={false} style={{ width: '100%', marginTop: 16 }} gutter={10}>
        <Col lg={6}>
          <Select
            value={paramsFilter.status}
            allowClear
            onChange={(value) => _filterByOption('status', value)}
            placeholder="Lọc theo trạng thái"
            style={{ width: '100%' }}>
            <Select.Option value="0">Đợi thanh toán</Select.Option>
            <Select.Option value="1">Đã thanh toán</Select.Option>
          </Select>
        </Col>
        <Col lg={6}>
          <Select
            value={paramsFilter['name_payment']}
            onChange={(value) => _filterByOption('name_payment', value)}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
            placeholder="Lọc theo phương thức thanh toán"
            style={{ width: '100%' }}>
            <Select.Option value="MoMo">MoMo</Select.Option>
            <Select.Option value="VNPay">VNPay</Select.Option>
          </Select>
        </Col>
        <Col lg={6}>
          <FilterDate
            paramsFilter={paramsFilter}
            setParamsFilter={setParamsFilter}
          />
        </Col>
      </Row>
      <br />
      <Table
        columns={columns}
        dataSource={inforOrder}
        size="small"
        loading={loadingTable}
        pagination={{
          total: countPage,
          current: paramsFilter.page,
          onChange(page, pageSize) {
            setParamsFilter({ ...paramsFilter, page: page, pageSize: pageSize })
          },
          pageSize: paramsFilter.pageSize,
          page: paramsFilter.page,
        }}
      />
    </div>
  )
}
