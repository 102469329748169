import React, { useEffect } from 'react'

import Views from './views'
import Loading from 'components/loading/Loading'
import { ACTION } from 'consts'
import { useDispatch } from 'react-redux'
import { notification } from 'antd'

export default function App() {
  const dispatch = useDispatch()

  //config notification
  notification.config({ duration: 2 })

  useEffect(() => {
    document.title = 'Electrical Engineer’s Tool'
    const token = localStorage.getItem('accessToken')
    if (token) dispatch({ type: ACTION.LOGIN, data: { accessToken: token } })
  }, [])

  return (
    <>
      <Loading />
      <Views />
    </>
  )
}
