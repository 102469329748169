import React, { useState, useEffect } from 'react'
import styles from './share.module.scss'

//import apis
import { updatePackage, getPackages } from 'apis/package'

//import antd
import {
  Button,
  notification,
  Table,
  Form,
  Modal,
  Input,
  InputNumber,
} from 'antd'

// html react parser
import { formatCash } from 'utils'

export default function Share() {
  const [packages, setPackages] = useState([])
  const [loadingTable, setLoadingTable] = useState(true)
  const [form] = Form.useForm()

  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên gói',
      dataIndex: 'name_package',
    },
    {
      title: 'Số tiền thuê gói',
      render: (text, record) => formatCash(record.price || 0),
    },
    {
      title: 'Số tháng sử dụng',
      dataIndex: 'amount_month',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
    },
    {
      title: 'Trạng thái',
      render: (text, record) => (record.is_active ? 'Hoạt động' : ''),
    },
    {
      render: (text, record) => <ModalUpdatePackage record={record} />,
    },
  ]

  const ModalUpdatePackage = ({ record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const _updatePackage = async () => {
      try {
        setLoadingTable(true)
        const body = form.getFieldsValue()
        const res = await updatePackage(body, record.package_id)
        setLoadingTable(false)

        if (res.status === 200) {
          if (res.data.success) {
            notification.success({ message: 'Cập nhật gói thành công' })
            _getPackages()
            toggle()
          } else
            notification.error({
              message:
                res.data.message || 'Cập nhật gói thất bại, vui lòng thử lại',
            })
        } else
          notification.error({
            message:
              res.data.message || 'Cập nhật gói thất bại, vui lòng thử lại',
          })
      } catch (err) {
        console.log(err)
        setLoadingTable(false)
      }
    }

    useEffect(() => {
      if (visible) form.setFieldsValue({ ...record })
    }, [visible])

    return (
      <>
        <Button type="primary" onClick={toggle}>
          Cập nhật
        </Button>

        <Modal
          onOk={_updatePackage}
          cancelText="Đóng"
          okText="Cập nhật"
          style={{ top: 20 }}
          onCancel={toggle}
          visible={visible}
          title="Cập nhật gói">
          <Form form={form} layout="vertical">
            <Form.Item
              label="Tên gói"
              name="name_package"
              rules={[{ message: 'Vui lòng nhập tên gói', required: true }]}>
              <Input placeholder="Nhập tên gói" />
            </Form.Item>
            <Form.Item
              label="Số tháng sử dụng"
              name="amount_month"
              rules={[
                { message: 'Vui lòng nhập số tháng sử dụng', required: true },
              ]}>
              <InputNumber
                min={0}
                style={{ width: '100%' }}
                placeholder="Nhập số tháng sử dụng"
              />
            </Form.Item>
            <Form.Item
              label="Số tiền thuê gói"
              name="price"
              rules={[
                { message: 'Vui lòng nhập số tiền thuê gói', required: true },
              ]}>
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                min={0}
                style={{ width: '100%' }}
                placeholder="Nhập số tiền thuê gói"
              />
            </Form.Item>
            <Form.Item label="Mô tả" name="description">
              <Input.TextArea
                rows={4}
                style={{ width: '100%' }}
                placeholder="Nhập số tiền thuê gói"
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }

  const _getPackages = async () => {
    try {
      setLoadingTable(true)
      const res = await getPackages()
      if (res.status === 200) setPackages(res.data.data)
      setLoadingTable(false)
    } catch (err) {
      console.log(err)
      setLoadingTable(false)
    }
  }

  useEffect(() => {
    _getPackages()
  }, [])

  return (
    <div>
      <div className={styles['share_header']}>
        <h2 className={styles['share_header_text']}>Quản lý gói</h2>
      </div>

      <div>
        <Table
          scroll={{ x: 'max-content' }}
          style={{ marginTop: 20, width: '100%' }}
          rowKey="idBlog"
          size="small"
          columns={columns}
          dataSource={packages}
          loading={loadingTable}
        />
      </div>
    </div>
  )
}
