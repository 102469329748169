import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import Paragraph from "antd/lib/skeleton/Paragraph";
import Text from "antd/lib/typography/Text";
import { uploadFile } from "apis/upload";
import React, { useState } from "react";
import styles from "./setting.module.scss";

const Setting = () => {
  const [picture, setPicture] = useState("");
  const [loading, setLoading] = useState(false);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const handleChangeImg = async (info) => {
    setLoading(true);
    if (info.file.status !== "done" && info.file.status === "uploading") {
      const urlImg = await uploadFile(info.file.originFileObj);
      // console.log(urlImg)
      setPicture(urlImg);
    }
    setLoading(false);
  };

  const copyText = (text) => {
    navigator.clipboard.writeText(text)
    message.success('Coppy thành công')
  }
  return (
    <div>
      <div className={styles["setting_content"]}>
        <h2 className={styles["setting_header_text"]}>Cài đặt</h2>
        <p>Lấy link hình ảnh :</p>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          onChange={handleChangeImg}
        >
          {picture ? (
            <img src={picture} alt="avatar" style={{ width: "100%" }} />
          ) : (
            uploadButton
          )}
        </Upload>
        <div className={styles["setting_text_link"]} >
        {picture ? <Text onClick={()=>copyText(picture)} copyable>{picture}</Text> : "" }
        </div>
      </div>
    </div>
  );
};
export default Setting;
