import React, { useState, useEffect } from 'react'
import styles from './share.module.scss'
import { Link } from 'react-router-dom'
import { ROUTES } from 'consts'

//import apis
import { getAllBlog, removeBlog } from 'apis/blog'

//import antd
import {
  Button,
  DatePicker,
  Table,
  Popconfirm as Reconfirm,
  message,
  notification,
} from 'antd'

// html react parser
import parse from 'html-react-parser'

export default function Share() {
  const [allBlog, setAllBlog] = useState([])
  const [selectKeys, setSelectKeys] = useState([])
  const [loadingTable, setLoadingTable] = useState(true)
  const [countPage, setCountPage] = useState('')
  const [paramsFilter, setParamsFilter] = useState({ page: 1, pageSize: 8 })

  const columns = [
    {
      title: 'Hình ảnh',
      dataIndex: 'image',
      render: (text, record, index) =>
        record.image ? (
          <img src={text} alt="" style={{ width: 50, height: 50 }} />
        ) : (
          ''
        ),
    },
    {
      key: 'title',
      width: 300,
      title: 'Tiêu đề',
      dataIndex: 'title',
      render: (text, record) => (
        <Link
          to={{ pathname: ROUTES.SHARE_CREATE, state: record }}
          style={{
            color: '#0070D7',
            cursor: 'pointer',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}>
          {text}
        </Link>
      ),
      sorter: (a, b) => a.title.length - b.title.length,
    },
    {
      width: 400,
      title: 'Links video',
      align: 'center',
      render: (text, record) =>
        record.videos &&
        record.videos.map((link) => (
          <a target="_blank" href={link}>
            {link}
          </a>
        )),
    },
    {
      width: 400,
      title: 'Nội dung',
      dataIndex: `content`,
      sorter: (a, b) => a.content.length - b.content.length,
      render: (text, record) => (
        <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
          {parse(text)}
        </div>
      ),
    },
  ]

  const _getAllBlog = async () => {
    try {
      const res = await getAllBlog(paramsFilter)
      console.log(res)
      setCountPage(res.data.count)
      if (res.data.data) {
        setAllBlog(res.data.data)
        setLoadingTable(false)
      } else {
        setLoadingTable(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const _removeBlog = async (id) => {
    try {
      const res = await removeBlog(id)
      console.log(res)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa bài viết thành công' })
          _getAllBlog(paramsFilter)
          setSelectKeys([])
        } else {
          notification.success({
            message: res.data.mess || 'Xóa bài viết thất bại',
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    _getAllBlog(paramsFilter)
  }, [paramsFilter])

  //search debounce
  // const _debounce = (fn, ms) => {
  //   let timer
  //   if (timer) clearTimeout(timer)

  //   timer = setTimeout(() => {
  //     fn()
  //   }, ms)
  // }

  return (
    <div>
      <div className={styles['share_header']}>
        <h2 className={styles['share_header_text']}>Quản lý bài viết</h2>
        <Button
          type="primary"
          style={{ paddingRight: 25, paddingLeft: 25, borderRadius: 5 }}>
          <Link to="/share/create">Tạo bài viết</Link>
        </Button>
      </div>

      <div>
        <Reconfirm
          title="Bạn có chắc chắn muốn xóa?"
          onConfirm={() => _removeBlog(selectKeys)}
          onCancel={() => {}}
          okText="Đồng ý"
          cancelText="Hủy"
          placement="leftTop"
          style={{ borderRadius: 5 }}>
          {selectKeys.length !== 0 && (
            <Button
              type="primary"
              danger
              style={{ marginTop: 20, marginBottom: 10, borderRadius: 5 }}>
              Xóa
            </Button>
          )}
        </Reconfirm>

        <Table
          scroll={{ x: 'max-content' }}
          style={{ marginTop: 20, width: '100%' }}
          rowKey="idBlog"
          size="small"
          columns={columns}
          dataSource={allBlog}
          loading={loadingTable}
          rowSelection={{
            selectedRowKeys: selectKeys,
            onChange: (keys, records) => {
              console.log('keys', keys)
              setSelectKeys(keys)
            },
          }}
          pagination={{
            total: countPage,
            current: paramsFilter.page,
            onChange(page, pageSize) {
              setParamsFilter({
                page: page,
                pageSize: pageSize,
              })
            },
            pageSize: paramsFilter.pageSize,
            // page: paramsFilter.pages,
          }}
        />
      </div>
    </div>
  )
}
